import React, { useEffect, useRef, useState } from 'react';
import content from '../content.json'; // Import content

const CoreFeatures = () => {
  const [isVisible, setIsVisible] = useState(false);
  const services = content.services;
  const ref = useRef(null); // Create a ref for the component

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Set isVisible to true when in view
          observer.disconnect(); // Stop observing after the animation triggers
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the component is in view
    );

    if (ref.current) {
      observer.observe(ref.current); // Observe the ref
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current); // Clean up the observer
      }
    };
  }, []);

  return (
    <div
      ref={ref} // Attach the ref to the main div
      className={`py-24 px-4 bg-white ${isVisible ? 'fade-left' : ''}`} 
      id='service'
    >
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-3xl font-bold text-center mb-6 text-[#7e5ca5]">Services</h2>
      </div>

      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
        {services.map((service, index) => (
          <div key={index} className="text-center">
            <div className="flex justify-center mb-4 relative">
              <div className="bg-[#7e5ca5] p-4 rounded-full animate-slide">
                <img src={service.icon} alt={service.title} className="w-8 h-8" />
              </div>
            </div>
            <h4 className="text-lg font-semibold text-gray-900 mb-2">{service.title}</h4>
            <p className="text-black">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoreFeatures;
