import React, { useEffect, useRef } from 'react';
import content from '../content.json'; // Import content

const SmartSchoolBanner = () => {
  const product = content.product;
  const bannerRef = useRef(null); // Create a ref for the banner

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-right-animation');
          } else {
            entry.target.classList.remove('fade-right-animation'); // Optional: remove animation when out of view
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the element is in view
    );

    if (bannerRef.current) {
      observer.observe(bannerRef.current);
    }

    return () => {
      if (bannerRef.current) {
        observer.unobserve(bannerRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={bannerRef} // Attach the ref to the container
      className="relative flex items-center justify-center md:h-screen bg-gray-800 bg-cover bg-center opacity-0" // Add opacity 0 initially
      id="product"
      style={{ backgroundImage: `url(${product.backgroundImage})` }}
    >
      <div className="absolute inset-0 bg-[#fff] opacity-60"></div>
      <div className="relative text-center text-white container mx-auto px-6 lg:px-24">
        <h1 className="text-4xl md:text-6xl font-bold mb-6 text-black pt-10 mt-10 md:mt-4 md:pt-4">
          {product.title}
        </h1>
        <p className="text-lg md:text-xl mb-6 text-black">
          {product.description}
        </p>
        <div className="flex justify-center">
          <a href={product.link} >
            <img
              src={product.logo}
              alt="Logo"
              className="w-32 h-32 md:w-48 md:h-48 object-contain"
            />
          </a>
        </div> 
      </div>
    </div>
  );
};

export default SmartSchoolBanner;
