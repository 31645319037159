import React, { useRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import content from '../content.json'; // Import content
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const Technology = () => {
  const sliderRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false); // State to track visibility
  const technologies = content.technology;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Set visibility to true when in view
          observer.disconnect(); // Stop observing after it becomes visible
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    const section = document.getElementById('technology'); // Get the section element
    if (section) {
      observer.observe(section); // Start observing the section
    }

    return () => {
      if (section) {
        observer.unobserve(section); // Clean up the observer on unmount
      }
    };
  }, []);

  return (
    <div
      className={` relative group bg-[#7e5ca5] p-4 ${isVisible ? 'fade-in-right' : ''}`} // Apply the fade-in-right class conditionally
      id="technology"
    >
      <div className='container mx-auto px-6 lg:px-24 py-24'>
      <h2 className="text-3xl font-bold text-center mb-6 text-[#fff]">Technology</h2>
      <div className="relative">
        <Slider ref={sliderRef} {...settings}>
          {technologies.map((tech, index) => (
            <div key={index} className="p-4">
              <div className="bg-white rounded-lg shadow-md overflow-hidden">
                <img
                  src={tech.image}
                  alt={tech.title}
                  className="w-full h-60 object-cover"
                />
                <div className="p-4">
                  <h3 className="text-lg font-semibold mb-2">{tech.title}</h3>
                  <p>{tech.content}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      </div>
    </div>
  );
};

export default Technology;
