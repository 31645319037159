import React from 'react';
import { useInView } from 'react-intersection-observer';
import content from '../content.json';

const AboutSection = () => {
  const { images, content: aboutContent, mission, vision } = content.about;
  const { ref: sectionRef, inView: sectionInView } = useInView({ triggerOnce: true });

  return (
   <div
  ref={sectionRef}
  className={`container mx-auto px-6 lg:px-24 py-24 ${sectionInView ? 'fade-in-left' : 'opacity-0'}`}
  id="about"
>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center pb-8">
    <div className= "col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-1 flex flex-col md:flex-row gap-4 md:gap-6">
      <img
        src={images[0].src}
        alt={images[0].alt}
        className={`${images[0].class} h-autow-full  md:w-1/2`}
      />
      <img
        src={images[1].src}
        alt={images[1].alt}
        className={`${images[1].class} h-autow-full  md:w-1/2`}
      />
    </div>

    <div className="flex flex-col space-y-4 md:space-y-6 col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-1">
      <h1 className="text-3xl md:text-4xl font-bold leading-tight text-[#7e5ca5]">
        {aboutContent.title}
      </h1>
      <p className="text-black text-center">
        {aboutContent.text}
      </p>

      <div className="flex flex-col md:flex-row md:space-x-8 space-y-4 md:space-y-0">
        <div className="flex flex-col items-center space-y-2 md:w-1/2">
          <div className="text-white p-4 rounded-full bg-[#7e5ca5]">
            <svg
              className="h-8 w-8 text-white"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="10" />
              <circle cx="12" cy="12" r="6" />
              <circle cx="12" cy="12" r="2" />
            </svg>
          </div>
          <h4 className="text-lg font-semibold">{mission.title}</h4>
          <p className="text-center text-black">
            {mission.description}
          </p>
        </div>

        <div className="flex flex-col items-center space-y-2 md:w-1/2">
          <div className="text-white p-4 rounded-full bg-[#7e5ca5]">
            <svg
              className="h-8 w-8 text-white"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
              <circle cx="12" cy="12" r="3" />
            </svg>
          </div>
          <h4 className="text-lg font-semibold">{vision.title}</h4>
          <p className="text-center text-black">
            {vision.description}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

  );
};

export default AboutSection;
