
import './App.css';
import Navbar from './Component/Navbar';
import Banner from './Component/Banner';
import Contact from './Component/Contact';
import Partners from './Component/Partners';
import Technology from './Component/Technology';
import About from './Component/About';
import Product from './Component/Product';
import Service from './Component/Service';


function App() {
  return (
    <div className="App">
      <Navbar />
      <Banner />
      <About />
      <Product />
      <Service />
      <Technology />
      <Partners />
      <Contact />
   
    </div>
  );
}

export default App;
