import React, { useState, useEffect } from 'react';
import content from '../content.json'; // Import the JSON file

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeNavItem, setActiveNavItem] = useState('Home');
  const [isSticky, setIsSticky] = useState(false);

  const sections = content.navbar.sections; // Use sections from JSON

  const handleNavItemClick = (item) => {
    setActiveNavItem(item);
    setIsMenuOpen(false);
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }

    const scrollPosition = window.scrollY + window.innerHeight / 2;

    sections.forEach((section) => {
      const element = document.getElementById(section.id);
      if (element) {
        const sectionTop = element.offsetTop;
        const sectionHeight = element.offsetHeight;

        if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
          setActiveNavItem(section.name);
        }
      }
    });
  };

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.replace('#', '');
      const section = sections.find(section => section.id === hash);
      if (section) {
        setActiveNavItem(section.name);
      }
    };

    window.addEventListener('hashchange', handleHashChange);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav
      className={`px-6 lg:px-24 p-6 transition duration-300 ${
        isSticky ? 'bg-white text-black shadow-lg' : 'bg-transparent text-white'
      } fixed top-0 left-0 right-0 z-20`}
    >
      
      <div className="container mx-auto flex justify-between items-center ">
      <div className="flex-shrink-0">
        <img
          src={isSticky ? content.navbar.logo.sticky : content.navbar.logo.default}
          alt="Logo"
          className="h-10"
        />
      </div>

      <div
        className="lg:hidden cursor-pointer text-3xl ml-auto"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        {isMenuOpen ? content.navbar.icons.menuOpen : content.navbar.icons.menuClose}
      </div>

      <div className="hidden lg:flex items-center space-x-6 ml-auto">
        <ul className="flex space-x-6 font-bold">
          {sections.map((item) => (
            <li
              key={item.name}
              className={`hover:underline underline-offset-4 decoration-2 hover:decoration-black cursor-pointer ${
                activeNavItem === item.name
                  ? `font-bold underline underline-offset-4 decoration-2 bg-clip-text text-transparent bg-[#fec322]`
                  : ''
              }`}
            >
              <a href={`#${item.id}`} onClick={() => handleNavItemClick(item.name)}>
                {item.name}
              </a>
            </li>
          ))}
        </ul>
      </div>

      {isMenuOpen && (
        <ul className="absolute top-full left-0 w-full bg-white lg:hidden flex flex-col items-center space-y-4 py-4 text-black">
          {sections.map((item) => (
            <li
              key={item.name}
              className={`hover:underline underline-offset-4 decoration-2 hover:decoration-black cursor-pointer text-black ${
                activeNavItem === item.name
                  ? `font-bold underline underline-offset-4 decoration-2 bg-clip-text text-[#fec322]`
                  : ''
              }`}
            >
              <a href={`#${item.id}`} onClick={() => handleNavItemClick(item.name)}>
                {item.name}
              </a>
            </li>
          ))}
          {/* <button
            className={`px-4 py-2 rounded-lg border transition duration-300 ${
              isSticky
                ? 'bg-black text-white border-black'
                : 'bg-white text-black border-black hover:bg-black hover:text-white'
            }`}
          >
            {content.navbar.buttons.whatsapp}
          </button> */}
        </ul>
      )}
      </div>
    </nav>
  );
};

export default Navbar;
