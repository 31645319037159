import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import content from '../content.json'; // Adjust the path according to your project structure

const ContactPage = () => {
  const form = useRef();
  const [messageSent, setMessageSent] = useState(false); // State to track if the message is sent

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_8ibyu1w', // Replace with your actual Service ID
        'template_sz57ht8', // Replace with your actual Template ID
        form.current,
        'N1Vq7MzESryjyQQnU' // Replace with your actual Public Key
      )
      .then(
        (result) => {
          setMessageSent(true); // Set success message to true (show modal)
          form.current.reset(); // Reset the form after successful submission
          console.log('Email sent: ', result.text);
        },
        (error) => {
          alert(`Failed to send message: ${error.text}. Please try again.`);
          console.error('Error:', error);
        }
      );
  };

  const closeModal = () => {
    setMessageSent(false); // Close the modal when the user clicks the close button or outside the modal
  };

  return (
    <>
      {/* Contact Section */}
      <section className="mb-12" id="contact">
        {/* Map Section */}
        <div id="map" className="relative h-[300px] overflow-hidden bg-cover bg-[50%] bg-no-repeat">
          <iframe
            src={content.contact?.map?.iframeSrc || ''}
            width="100%"
            height={content.contact?.map?.height || '300'}
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>

        {/* Contact Form Section */}
        <div className="container mx-auto px-6 lg:px-24 md:px-0">
          <div className="block rounded-lg bg-[hsla(0,0%,100%,0.8)] px-6 py-12 shadow-lg md:py-16 md:px-12 -mt-[100px] backdrop-blur-[30px] border border-gray-300">
            <div className="flex flex-wrap">
              {/* Form Section */}
              <div className="mb-12 w-full md:px-3 lg:w-5/12 lg:px-6">
                <form ref={form} onSubmit={sendEmail}>
                  {/* Name Input */}
                  <div className="relative mb-6">
                    <input
                      type="text"
                      className="peer block w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder-opacity-100 peer-focus:text-primary"
                      id="name"
                      name={content.contact?.form?.fields?.[0]?.name || 'name'}
                      placeholder={content.contact?.form?.fields?.[0]?.placeholder || 'Your Name'}
                      required={content.contact?.form?.fields?.[0]?.required || true}
                    />
                  </div>

                  {/* Phone Input */}
                  <div className="relative mb-6">
                    <input
                      type="tel"
                      className="peer block w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder-opacity-100 peer-focus:text-primary"
                      id="phonenumber"
                      name={content.contact?.form?.fields?.[1]?.name || 'phone'}
                      placeholder={content.contact?.form?.fields?.[1]?.placeholder || 'Your Phone'}
                      required={content.contact?.form?.fields?.[1]?.required || true}
                    />
                  </div>

                  {/* Email Input */}
                  <div className="relative mb-6">
                    <input
                      type="email"
                      className="peer block w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder-opacity-100 peer-focus:text-primary"
                      id="email"
                      name={content.contact?.form?.fields?.[2]?.name || 'email'}
                      placeholder={content.contact?.form?.fields?.[2]?.placeholder || 'Your Email'}
                      required={content.contact?.form?.fields?.[2]?.required || true}
                    />
                  </div>

                  {/* Message Input */}
                  <div className="relative mb-6">
                    <textarea
                      className="peer block w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder-opacity-100 peer-focus:text-primary"
                      id="message"
                      name={content.contact?.form?.fields?.[3]?.name || 'message'}
                      rows={content.contact?.form?.fields?.[3]?.rows || 5}
                      placeholder={content.contact?.form?.fields?.[3]?.placeholder || 'Your Message'}
                      required={content.contact?.form?.fields?.[3]?.required || true}
                    ></textarea>
                  </div>

                  {/* Submit Button */}
                  <button
                    type="submit"
                    className="w-full rounded bg-[#7e5ca5] text-white px-6 py-2 text-xs font-medium uppercase leading-normal"
                  >
                    {content.contact?.form?.submitButton?.text || 'Send Message'}
                  </button>
                </form>
              </div>

              {/* Contact Info Section */}
              <div className="w-full lg:w-7/12">
  <div className="flex flex-wrap">
    {content.contact?.contactInfo?.map((info, index) => (
      <div key={index} className="mb-12 w-full md:w-6/12 lg:w-full xl:w-6/12">
        <div className="flex items-start text-left">
          <div className="shrink-0">
            <div className="inline-block rounded-md bg-[#7e5ca5] p-2 text-primary">
              {info.icon === "location" ? (
                <svg className="h-7 w-7 text-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <path d="M21 3L14.5 21a.55 .55 0 0 1 -1 0L10 14L3 10.5a.55 .55 0 0 1 0 -1L21 3" />
                </svg>
              ) : info.icon === "email" ? (
                <svg className="h-7 w-7 text-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <rect x="3" y="5" width="18" height="14" rx="2" />
                  <polyline points="3 7 12 13 21 7" />
                </svg>
              ) : null}
              {/* LinkedIn Icon */}
              {info.icon === "linkedin" ? (
                <a href={info.linkedinUrl} target="_blank" rel="noopener noreferrer">
                  <svg className="h-8 w-8 text-white " viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
                    <rect x="2" y="9" width="4" height="12" />
                    <circle cx="4" cy="4" r="2" />
                  </svg>
                </a>
              ) : null}
            </div>
          </div>
          <div className="ml-6 ">
            <p className="mb-2 font-bold">{info?.title || 'Contact Information'}</p>
            <p className="text-sm text-neutral-500">{info?.address || info?.email || 'N/A'}</p>
          </div>
        </div>
      </div>
    ))}
  </div>
</div>



            </div>
          </div>
        </div>

        {/* Success Modal */}
        {messageSent && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded shadow-lg">
              <h2 className="text-lg font-bold">Success</h2>
              <p>{content.contact?.successMessage?.text || 'Your message has been sent successfully.Will get back to you soon!'}</p>
              <button
                onClick={closeModal}
                className="mt-4 bg-[#7e5ca5] text-white px-4 py-2 rounded"
              >
                {content.contact?.successMessage?.closeButtonText || 'Close'}
              </button>
            </div>
          </div>
        )}
      </section>

      {/* Footer Section */}
      {/* <footer className="bg-white text-black py-4">
        <div className="container mx-auto text-center">
          <p className="mb-2">{content.contact?.footer?.text || 'Footer Text'}</p>
          <img
            src={content.contact?.footer?.logoSrc || '/default-logo.png'}
            alt="Logo"
            className="mx-auto mb-2 h-10" 
          />
          <div className="flex justify-center space-x-4 mt-2">
            {content.contact?.footer?.socialMedia?.map((media, index) => (
              <a
                key={index}
                href={media?.link || '#'}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blue-500"
              >
                <img src={media?.iconSrc || '/default-icon.png'} alt={media?.altText || 'Social Media'} className="w-5 h-5" />
              </a>
            ))}
          </div>
        </div>
      </footer> */}
    </>
  );
};

export default ContactPage;

