import React, { useEffect, useRef, useState } from 'react';
import content from '../content.json';

const TrustedPartners = () => {
  const [isVisible, setIsVisible] = useState(false);
  const partnerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing once the animation has triggered
        }
      },
      {
        threshold: 0.1, // Adjust threshold as needed
      }
    );

    if (partnerRef.current) {
      observer.observe(partnerRef.current);
    }

    return () => {
      if (partnerRef.current) {
        observer.unobserve(partnerRef.current); // Cleanup
      }
    };
  }, []);

  return (
    <div
      className={`py-24 bg-white my-10 ${isVisible ? 'fade-left' : ''}`}
      id='partner'
      ref={partnerRef} // Attach the ref to this div
    >
      <div className="text-center">
        <h2 className="text-3xl text-[#7e5ca5] font-bold mt-2 mb-6">
          {content.partnersSection.title}
        </h2>
      </div>
      <div
        className={`mt-8 grid ${
          content.partnersSection.partners.length === 1 ? 'grid-cols-1' : 'grid-cols-2 sm:grid-cols-4'
        } gap-8 container mx-auto px-6 lg:px-12`}
      >
        {content.partnersSection.partners.map((partner, index) => (
          <div key={index} className="flex justify-center">
            <img src={partner.logo} alt={partner.name} className="h-32" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrustedPartners;
