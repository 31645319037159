import React, { useState, useEffect } from 'react';
import content from '../content.json'; // Importing the JSON

const HeroSection = () => {
  const { backgroundImage, overlayColor, titles, scrollText } = content.banner;
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % titles.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [titles.length]);

  return (
    <div className="relative h-screen bg-cover bg-center "
      style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="absolute inset-0" style={{ backgroundColor: overlayColor, opacity: 0.5 }} id='home'></div>
      <div className="relative flex items-center justify-start h-full container mx-auto px-6 lg:px-24">
        <div className="text-left text-white max-w-2xl">
          <h1 className="text-5xl font-bold mb-4">
            {titles[currentIndex].title}
          </h1>
          <p className="text-lg mb-6">
            {titles[currentIndex].subtitle}
          </p>
        </div>
      </div>
      <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2">
        <span className="text-white text-lg">{scrollText}</span>
        <div className="flex justify-center">
          <a href="#about">
            <svg
              className="h-8 w-8 text-white mt-4 animate-bounce" viewBox="0 0 24 24" fill="none" stroke="currentColor"
              strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="10" />
              <polyline points="8 12 12 16 16 12" />
              <line x1="12" y1="8" x2="12" y2="16" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
